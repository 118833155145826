// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  user: icon("ic_user"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
  scheme: icon("ic_scheme"),
  transaction: icon("ic_transaction"),
  services: icon("ic_services"),
  funds: icon("ic_funds"),
  settings: icon("ic_settings"),
  help: icon("ic_help"),
  finger: icon("ic_finger"),
};

const NavConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "My Stats",
    items: [
      {
        title: "Home",
        path: PATH_DASHBOARD.home,
        icon: ICONS.dashboard,
      },
      {
        title: "Money Transfer",
        path: PATH_DASHBOARD.dmt,
        roles: ["agent"],
      },
      {
        title: "Quick",
        path: PATH_DASHBOARD.dmt,
        roles: ["agent"],
      },
      {
        title: "Vendor Payments",
        path: PATH_DASHBOARD.vendorpayments,
        roles: ["agent"],
      },
    ],
  },
  {
    subheader: "Dashboard",
    items: [
      {
        title: "Reports",
        path: PATH_DASHBOARD.transaction.root,
        icon: ICONS.transaction,
        children: [
          {
            title: "My Transactions",
            path: PATH_DASHBOARD.transaction.mytransaction,
          },
          {
            title: "Wallet Ledger",
            path: PATH_DASHBOARD.transaction.walletladger,
          },
        ],
      },
      {
        title: "Schemes",
        path: PATH_DASHBOARD.scheme.root,
        icon: ICONS.scheme,
        children: [
          {
            title: "All Scheme",
            path: PATH_DASHBOARD.scheme.allscheme,
          },
        ],
      },
    ],
  },
];

export default NavConfig;
